<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="types-div">
        <label v-for="item in types" :class="{'current-label': currentType != null && currentType.id === item.id}" @click="onSelectType(item)">{{ item.name }}</label>
      </div>
      <div class="edit-div">
        <label>类型名称：</label>
        <label class="text-input-label"><input type="text" v-model="typeName" maxlength="10" placeholder="请输入类型名称"></label>
        <input class="save-input" type="button" :value="currentType == null ? '新增' : '保存'" @click="onSave">
        <input v-show="currentType != null" class="save-input" type="button" value="取消" @click="onCancel">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirmMemberTypeItems, postFirmMemberTypeAdd, postFirmMemberTypeEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    firmId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentType: null,
      types: [],
      typeName: "",
      tipMsg: ""
    }
  },
  created() {
    this.iniData();
  },
  methods: {
    iniData() {
      getFirmMemberTypeItems({id: this.firmId})
        .then(data => {
          this.types = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询数据失败，请稍后重试");
          }
          this.$layer.close(this.layerid);
        });
    },
    onSelectType(item) {
      this.currentType = item;
      this.typeName = item.name;
    },
    onCancel() {
      this.currentType = null;
      this.typeName = "";
    },
    onSave() {
      if(this.typeName.trim().length < 1){
        this.setTipsMsg("请输入类型名称");
        return;
      }

      if(this.currentType == null){
        postFirmMemberTypeAdd({firmId: this.firmId, name: this.typeName.trim()})
          .then(() => {
            this.$layer.close(this.layerid);
            this.$layer.msg("添加成功");
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.setTipsMsg(error.data.msg);
            } else {
              this.setTipsMsg("操作失败");
            }
          });
      }else{
        postFirmMemberTypeEdit({id: this.currentType.id, firmId: this.firmId, name: this.typeName.trim()})
          .then(() => {
            this.$layer.close(this.layerid);
            this.$layer.msg("添加成功");
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.setTipsMsg(error.data.msg);
            } else {
              this.setTipsMsg("操作失败");
            }
          });
      }
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .types-div {
      display: flex;
      flex-wrap: wrap;
      label {
        padding: 3px 6px;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
      .current-label {
        border: 1px solid #ee9900;
      }
    }
    .edit-div {
      margin-top: 30px;
      text-align: center;
      .text-input-label {
        input {
          width: 158px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
      }
      .save-input {
        height: 35px;
        padding: 0 10px;
        color: white;
        font-size: 16px;
        border: none;
        background-color: #4676c8;
        margin-left: 10px;
        vertical-align: middle;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 30px;
      }
    }
  }
}
</style>