<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div content-middle-div">
          <div class="item-name-div">商会名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:30" v-slot="{ errors }">
              <label><input class="firm-name-div" name="firmName" type="text" v-model="firmInfo.name" maxlength="30" placeholder="请输入商会名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div logo-div">品牌Logo：</div>
          <div class="item-content-div">
            <div class="logo-img-div" v-if="firmInfo.logoImg.data != null">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelLogo" />
              <img v-if="firmInfo.logoImg.file == null" :src="`${$imgBaseURL}/${firmInfo.logoImg.data}`" alt="">
              <img v-else :src="firmInfo.logoImg.data" alt="">
            </div>
            <div v-else>
              <label class="upload-img-label" @change="onAddLogo($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div">商会图片：</div>
          <div class="firm-img-div">
            <div v-for="(v, k) in firmInfo.img" class="preview-img-div">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg(k)">
              <img v-if="Object.prototype.hasOwnProperty.call(v, 'index')" class="preview-img" :src="`${$imgBaseURL}/${v.data}`" alt="" @click="onShowBigImgDiv($event)">
              <img v-else class="preview-img" :src="v.data" alt="" @click="onShowBigImgDiv($event)">
            </div>
            <div v-show="firmInfo.img.length < 3">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">基本信息：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:2000" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="firmInfo.content" maxlength="2000" placeholder="请输入基本信息"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div">联系电话：</div>
          <div class="item-content-div">
            <validation-provider rules="required|phone" v-slot="{ errors }">
              <label><input name="phone" type="text" v-model="firmInfo.phone" maxlength="11" placeholder="请输入联系电话"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div">电子邮箱：</div>
          <div class="item-content-div">
            <validation-provider rules="email" v-slot="{ errors }">
              <label><input name="email" type="text" v-model="firmInfo.email" maxlength="60" placeholder="请输入邮箱"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div">地区：</div>
          <div class="item-content-div">
            <validation-provider rules="required" v-slot="{ errors }">
              <label>
                <select class="prefecture-select" v-model="firmInfo.provinceId" name="province" @change="onProvinceCity($event)">
                  <option value="">请选择省份</option>
                  <option v-for="item in firmInfo.provinces" :value="item.id">{{item.name}}</option>
                </select>
              </label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }">
              <label>
                <select class="prefecture-select" v-model="firmInfo.cityId" name="city" @change="onProvinceCounty($event)">
                  <option value="">请选择城市</option>
                  <option v-for="item in firmInfo.cities" :value="item.id">{{item.name}}</option>
                </select>
              </label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }">
              <label>
                <select class="prefecture-select" v-model="firmInfo.areaId" name="area">
                  <option value="">请选择地区</option>
                  <option v-for="item in firmInfo.counties" :value="item.id">{{item.name}}</option>
                </select>
              </label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div content-middle-div">
          <div class="item-name-div">详细地址：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:50" v-slot="{ errors }">
              <label><input name="address" class="address-input" type="text" v-model="firmInfo.address" maxlength="50" placeholder="请输入详细地址"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { getFirmDetails, getProvinces, getCities, getCounties, postFirmEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    firmId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      firmInfo: {
        logoImg: {data: null, file: null},
        img: [],
        provinces: [],
        cities: [],
        counties: [],
      },
      delImgIndex: [],
      isShowAddLogo: true,
      isShowBigImg: false,
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getFirmDetails({id: this.firmId})
        .then(data => {
          if(Object.prototype.hasOwnProperty.call(data, "logo")){
            data.logoImg = {data: data.logo, file: null};
          }
          if(Object.prototype.hasOwnProperty.call(data, "img")){
            let img = [];
            data.img.forEach((v, index) => {
              img.push({
                index: index,
                data: v,
              });
            })
            data.img = img;
          }else{
            data.img = [];
          }
          data.provinces = [];
          data.cities = [];
          data.counties = [];
          this.firmInfo = data;
          this.onProvinceChanged();
          this.onProvinceCity(null, data.provinceId);
          this.onProvinceCounty(null, data.cityId);
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询省份信息失败，请稍后重试");
          }
          this.$layer.close(this.layerid);
        });
    },
    onProvinceChanged() {
      getProvinces()
        .then(data => {
          this.firmInfo.provinces = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询省份信息失败，请稍后重试");
          }
        });
    },
    onProvinceCity(event, province) {
      let provinceId = event == null ? province : event.target.value;
      if(provinceId > 0){
        getCities({provinceId: provinceId})
          .then(data => {
            this.firmInfo.cities = data;
            if(province == null){
              this.firmInfo.cityId = "";
              this.firmInfo.counties = [];
              this.firmInfo.areaId = "";
            }
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询城市信息失败，请稍后重试");
            }
          });
      }else{
        this.firmInfo.cities = [];
        this.firmInfo.cityId = "";
        this.firmInfo.counties = [];
        this.firmInfo.areaId = "";
      }
    },
    onProvinceCounty(event, city) {
      let cityId = event == null ? city : event.target.value;
      if(cityId > 0){
        getCounties({cityId: cityId})
          .then(data => {
            this.firmInfo.counties = data;
            if(city == null){
              this.firmInfo.areaId = "";
            }
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询地区信息失败，请稍后重试");
            }
          });
      }else{
        this.firmInfo.counties = [];
        this.firmInfo.areaId = "";
      }
    },
    onAddLogo(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.firmInfo.logoImg.data = reader.result;
        that.firmInfo.logoImg.file = img;
        that.isShowAddLogo = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.firmInfo.img.push({
          file: img,
          data: reader.result,
        });
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelLogo() {
      this.firmInfo.logoImg.data = null;
      this.firmInfo.logoImg.file = null;
    },
    onDelImg(index) {
      let item = this.firmInfo.img.splice(index, 1);
      if(Object.prototype.hasOwnProperty.call(item[0], "index")){
        this.delImgIndex.push(item[0].index);
      }
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      let formData = new FormData();
      formData.append("id", this.firmInfo.id);
      formData.append("name", this.firmInfo.name);
      formData.append("content", this.firmInfo.content);
      formData.append("phone", this.firmInfo.phone);
      formData.append("email", this.firmInfo.email);
      formData.append("provinceId", this.firmInfo.provinceId);
      formData.append("cityId", this.firmInfo.cityId);
      formData.append("areaId", this.firmInfo.areaId);
      formData.append("address", this.firmInfo.address);
      if(this.firmInfo.logoImg.file != null){
        formData.append("logoFile", this.firmInfo.logoImg.file);
      }
      this.firmInfo.img.forEach(v => {
        formData.append("file", v.file);
      })
      this.delImgIndex.forEach(v => {
        formData.append("delImg", v);
      })

      this.$refs.form.validate().then(res => {
        if(res){
          postFirmEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      margin-bottom: 8px;
      .item-name-div {
        width: 90px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        display: inline-flex;
        input {
          width: 158px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .logo-img-div {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            align-items: center;
          }
          .del-img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .prefecture-select {
          height: 33px;
          border: 1px solid #dcdcdc;
        }
        .firm-name-div {
          width: 218px;
        }
        .address-input {
          width: 318px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
      .firm-img-div {
        display: flex;
        .preview-img-div {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-right: 10px;
          .del-img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            right: 0;
          }
          .preview-img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            align-items: center;
          }
        }
      }
    }
    .content-middle-div {
      align-items: center;
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>