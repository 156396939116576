<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'商会管理'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">商会名称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template v-if="$validateAuthority(2400000,2407000,2407001)" #tableSubHead>
          <th class="table-sub-head-th" :colspan="tableFieldNum">
            <div class="div-btn cursor-el append-btn" @click="onShowAdd">
              <img src="../../assets/add-data.png" height="14" width="14" alt="" />
              <label class="cursor-el">添加商会</label>
            </div>
          </th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.name === 'content'"><div class="content-div">{{props.item['content']}}</div></td>
              <td v-else-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <template v-if="$validateAuthority(2400000,2407000,2407003)">
                  <span v-if="props.item.state === 1" class="cursor-el" @click="onChangeState(props.item, false)">禁用</span>
                  <span v-else class="cursor-el" @click="onChangeState(props.item, true)">启用</span>
                </template>
                <span v-if="$validateAuthority(2400000,2407000,2407002)" class="cursor-el" @click="onShowInfo(props.item)">查看</span>
                <span v-if="$validateAuthority(2400000,2407000,2407002)" class="cursor-el" @click="onShowTypes(props.item)">成员类型</span>
                <span v-if="$validateAuthority(2400000,2407000,2407004)" class="cursor-el" @click="onDel(props.item)">删除</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import FirmAddLayer from "../layer/FirmAddLayer";
import FirmEditLayer from "../layer/FirmEditLayer";
import FirmMemberTypeEditLayer from "../layer/FirmMemberTypeEditLayer";
import { getFirmItems, postFirmStateEdit, postFirmDel } from "../../common/api";

export default {
  name: "Firms",
  components: { TableBox },
  data() {
    return {
      action: getFirmItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "商会编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "商会名称", name: "name", title: "name", isHtml: false, value: this.getObjValue },
        { state: true, alias: "地区", name: "prefecture", title: "prefecture", isHtml: false, value: this.getObjValue },
        { state: true, alias: "状态", name: "state", title: "state", isHtml: true, value: this.getObjValue },
        { state: true, alias: "更新时间", name: "updateTime", title: "updateTime", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2400000,2407000,[2407002,2407003,2407004]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if (field === "state") {
        return item[field] === 1 ? "<span class='success-txt'>启用</span>" : "<span class='red-txt'>禁用</span>";
      } else {
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onShowAdd() {
      this.$layer.iframe({
        content: {
          content: FirmAddLayer,
          parent: this,
          data: {
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "610px"],
        title: "添加商会"
      });
    },
    onShowInfo(item) {
      this.$layer.iframe({
        content: {
          content: FirmEditLayer,
          parent: this,
          data: {
            firmId: item.id,
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "610px"],
        title: "编辑商会"
      });
    },
    onShowTypes(item) {
      this.$layer.iframe({
        content: {
          content: FirmMemberTypeEditLayer,
          parent: this,
          data: {
            firmId: item.id
          }
        },
        area: ["600px", "600px"],
        title: "编辑商会成员类型"
      });
    },
    onChangeState(item, isEnable) {
      this.$layer.confirm(`是否【${isEnable ? "启用" : "禁用"}】商会【${item.name}】？`, layerid => {
        postFirmStateEdit({ id: item.id, state: isEnable ? 1 : 0 })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    },
    onDel(item) {
      this.$layer.confirm(`是否删除商会【${item.name}】？`, layerid => {
        postFirmDel({ id: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 160px;
    }
    .table-sub-head-th {
      .div-btn {
        float: left;
        width: 88px;
        height: 23px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .append-btn {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    .content-div {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>